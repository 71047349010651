import { useCallback } from "react";
import { ModalProps, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { EndpointOut, TemplateOut } from "svix";

import Button from "@svix/common/widgets/Button";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import EventsList from "@svix/common/widgets/EventsList";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@svix/common/widgets/Modal";

import { getSvix } from "src/api";
import { useAllEventTypes } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";

export interface ConfigurationModalProps extends Omit<ModalProps, "children"> {
  integrationEndpoint: EndpointOut;
  template: TemplateOut;
}

export default function ConfigurationModal(props: ConfigurationModalProps) {
  const { isOpen, onClose, integrationEndpoint, template } = props;
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.auth.user)!;
  const isIntegrationsButton = useAppSelector(
    (state) => state.embedConfig.integrationButtonType === template.kind
  );
  const formCtx = useForm({
    defaultValues: {
      filterTypes: integrationEndpoint.filterTypes,
    },
  });
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();
  const toast = useToast();

  const { data: allEvents } = useAllEventTypes();

  const saveEventsMutation = useMutation(async () => {
    let filterTypes = formCtx.getValues("filterTypes");
    if (!filterTypes || filterTypes.length === 0) {
      filterTypes = undefined;
    }
    const api = getSvix();
    await api.endpoint.update(user.app.id, integrationEndpoint.id, {
      ...integrationEndpoint,
      filterTypes,
    });
    await queryClient.invalidateQueries(["endpoints", integrationEndpoint.id]);
    onModalClose();
  });

  const onModalClose = useCallback(() => {
    if (isIntegrationsButton) {
      window.close();
    } else {
      onClose();
    }
  }, [isIntegrationsButton, onClose]);

  const deleteIntegrationMutation = useMutation(async () => {
    const api = getSvix();
    await api.endpoint.delete(user.app.id, integrationEndpoint.id);
    await queryClient.invalidateQueries("endpoints");
    onConfirmationClose();
    toast({
      title: `${template.name} integration disabled`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onModalClose();
  });

  return (
    <>
      <Modal isOpen={isOpen} onClose={onModalClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>{template.name} Integration</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Select the events that you want to receive in Slack</Text>
            <EventsList
              name="filterTypes"
              control={formCtx.control}
              availableEvents={
                allEvents?.data.filter((et) => template.filterTypes?.includes(et.name)) ||
                []
              }
            />
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button variant="link" colorScheme="red" onClick={onConfirmationOpen}>
              Disable
            </Button>
            <Button
              isLoading={saveEventsMutation.isLoading}
              onClick={() => saveEventsMutation.mutate()}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        title={`Disable ${template.name} integration`}
        onCancel={onConfirmationClose}
        onOk={deleteIntegrationMutation.mutateAsync}
        labelOk="Disable"
        colorScheme="red"
      >
        <Text>{`Are you sure you want to disable the ${template.name} integration?`}</Text>
      </ConfirmationDialog>
    </>
  );
}
