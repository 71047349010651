import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
  UseQueryResult,
} from "react-query";
import { useDispatch } from "react-redux";
import { ApiException, HttpErrorOut, EndpointOut } from "svix";

import usePagination, {
  FastApiResponse,
  Iterator,
  IRequestElems,
  IPaginationOpts,
  FastApiWithPrevResponse,
  usePaginationWithPrevIter,
} from "@svix/common/hooks/pagination";

import { getSvix } from "src/api";
import { pushError } from "src/store/errors";

export function useAppPagination<T extends FastApiResponse>(
  key: string | string[],
  request: (iterator: Iterator) => Promise<T>,
  opts?: IPaginationOpts
): [T | undefined, IRequestElems] {
  const dispatch = useDispatch();
  const onError = (e: unknown) => {
    if (e instanceof Error && !isNotFoundError(e)) {
      dispatch(pushError(e));
    }
  };

  return usePagination(key, request, {
    onError,
    ...opts,
  });
}

export function useAppWithPrevIterPagination<T extends FastApiWithPrevResponse>(
  key: string | string[],
  request: (iterator: Iterator) => Promise<T>,
  opts?: IPaginationOpts
): [T | undefined, IRequestElems] {
  const dispatch = useDispatch();
  const onError = (e: unknown) => {
    if (e instanceof Error && !isNotFoundError(e)) {
      dispatch(pushError(e));
    }
  };

  return usePaginationWithPrevIter(key, request, {
    onError,
    ...opts,
  });
}

export function useAppQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    failSilently?: boolean;
  }
): UseQueryResult<TData, TError> {
  const dispatch = useDispatch();
  return useQuery(queryKey, queryFn, {
    onError: (e) => {
      if (e instanceof Error && !isNotFoundError(e) && !options?.failSilently) {
        dispatch(pushError(e));
      }
    },
    ...options,
  });
}

function isNotFoundError(e: Error): boolean {
  return (
    e instanceof ApiException &&
    e.body instanceof HttpErrorOut &&
    e.body.code === "not_found"
  );
}

export function useAllEventTypes(
  opts: { withContent: boolean; includeArchived: boolean } = {
    withContent: false,
    includeArchived: true,
  }
) {
  const { withContent, includeArchived } = opts;
  return useAppQuery(["eventTypes", withContent, includeArchived], async () => {
    const api = getSvix();
    const out = await api.eventType.list({ limit: 250, includeArchived, withContent });
    while (!out.done) {
      const iterator = out.iterator;
      const curr = await api.eventType.list({
        limit: 250,
        iterator,
        withContent,
        includeArchived,
      });

      out.data.push(...curr.data);
      out.done = curr.done;
      out.iterator = curr.iterator;
    }

    return out;
  });
}

export function useAvailableEventTypes(endpoint: EndpointOut) {
  const { data } = useAppQuery("eventTypes", async () => {
    const api = getSvix();
    return api.eventType.list({ limit: 250, withContent: true });
  });

  return {
    subscribedEvents: endpoint.filterTypes,
    unsubscribedEvents: data?.data
      .map((et) => et.name)
      .filter((et) => !endpoint.filterTypes?.includes(et)),
  };
}
