import { useRef, useEffect } from "react";
import { useColorMode } from "@chakra-ui/react";
import { basicSetup } from "@codemirror/basic-setup";
import { defaultHighlightStyle } from "@codemirror/highlight";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { EditorState } from "@codemirror/state";
import { oneDarkTheme, oneDarkHighlightStyle } from "@codemirror/theme-one-dark";
import { EditorView } from "@codemirror/view";

import { Lang } from "./Lang";

interface CodeEditorProps {
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: any;
  lang: Lang;
  dark?: boolean;
  readOnly?: boolean;
}

export default function CodeEditor(props: CodeEditorProps) {
  const { onChange, onFocus, onBlur, value, lang, dark, readOnly } = props;

  const { colorMode } = useColorMode();

  const editor = useRef<HTMLDivElement | null>(null);
  const cmView = useRef<EditorView>();
  const onChangeRef = useRef<(val: string) => void>(onChange);

  useEffect(() => {
    // Mirror onChange function in a ref
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    // Track external changes
    if (!cmView.current?.hasFocus) {
      cmView.current?.dispatch({
        changes: { from: 0, to: cmView.current.state.doc.length, insert: value },
      });
    }
  }, [value]);

  useEffect(() => {
    if (cmView.current) {
      // We rebuild the editor if the colorMode changes
      cmView.current.destroy();
    }
    const state = EditorState.create({
      doc: value,
      extensions: [
        EditorView.editable.of(!readOnly),
        ...themeExtensions[dark ? "dark" : colorMode],
        basicSetup,
        LANGUAGES[lang],
        EditorView.updateListener.of((v) => {
          if (v.docChanged && v.view.hasFocus) {
            onChangeRef.current(v.state.doc.toString());
          }
          if (v.focusChanged) {
            if (v.view.hasFocus && onFocus) {
              onFocus();
            } else if (onBlur) {
              onBlur();
            }
          }
        }),
      ],
    });
    cmView.current = new EditorView({
      state,
      parent: editor.current ?? undefined,
    });

    return () => {
      cmView.current?.destroy();
    };
  }, [colorMode, dark, lang, readOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div ref={editor} />;
}

const LANGUAGES = {
  [Lang.Json]: json(),
  [Lang.Javascript]: javascript(),
};

const themeExtensions = {
  light: [defaultHighlightStyle],
  dark: [oneDarkTheme, oneDarkHighlightStyle],
};
