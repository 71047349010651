import { Svix, EndpointIn, EndpointOut } from "svix";
import { RequestContext, HttpMethod } from "svix/dist/openapi";

import { Iterator } from "@svix/common/hooks/pagination";

import { StreamSinkIn, StreamSinkOut } from "./streamSinks";

export type SinkIn = EndpointIn & SinkType;

export type HttpSink = {
  type: "http";
  url: string;
};

export type RabbitMQSink = {
  type: "rabbitMQ";
  uri: string;
  routingKey: string;
};

export type SQSSink = {
  type: "sqs";
  region: string;
  queueDsn: string;
  accessKey: string;
  secretKey: string;
};

export type EventStreamSink = {
  type: "eventStream";
};

export type SinkType = RabbitMQSink | SQSSink | HttpSink | EventStreamSink;

export type SinkTypeName = "rabbitMQ" | "sqs" | "http" | "eventStream";

export type SinkOut = Omit<EndpointOut, "url"> & SinkType;

export interface ListSinksOut {
  data: SinkOut[];
  iterator: Iterator;
  done: boolean;
}

export interface ListStreamSinksOut {
  data: StreamSinkOut[];
  iterator: Iterator;
  done: boolean;
}

export interface TransformationIn {
  code?: string;
  enabled: boolean;
}

export interface TransformationOut {
  code?: string;
  enabled: boolean;
}

interface StreamSinkSecretOut {
  key: string | null;
}

export class SinksApi {
  private readonly basePath = "/api/v1/app";
  private readonly svix: Svix;

  constructor(svix: Svix) {
    this.svix = svix;
  }

  private async getReqContext(path: string, method: HttpMethod) {
    const requestContext = this.svix._configuration.baseServer.makeRequestContext(
      path,
      method
    );
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
    const authMethod = this.svix._configuration.authMethods["HTTPBearer"];
    await authMethod?.applySecurityAuthentication(requestContext);
    return requestContext;
  }

  async createSink(appId: string, sinkIn: SinkIn): Promise<SinkOut> {
    const path = `${this.basePath}/${appId}/sink`;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);

    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(sinkIn));

    return this.sendRequest<SinkOut>(requestContext);
  }

  async listSinks(appId: string): Promise<ListSinksOut> {
    const path = `${this.basePath}/${appId}/sink`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest<ListSinksOut>(requestContext);
  }

  async getSink(appId: string, sinkId: string): Promise<SinkOut> {
    const path = `${this.basePath}/${appId}/sink/${sinkId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest<SinkOut>(requestContext);
  }

  async createStreamSink(appId: string, sinkIn: StreamSinkIn): Promise<StreamSinkOut> {
    const path = `${this.basePath}/${appId}/stream/sink`;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);
    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(sinkIn));
    return this.sendRequest<StreamSinkOut>(requestContext);
  }

  async updateStreamSink(
    appId: string,
    sinkId: string,
    sinkIn: Partial<StreamSinkIn>
  ): Promise<StreamSinkOut> {
    const path = `${this.basePath}/${appId}/stream/sink/${sinkId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.PUT);
    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(sinkIn));
    return this.sendRequest<StreamSinkOut>(requestContext);
  }

  async deleteStreamSink(appId: string, sinkId: string): Promise<void> {
    const path = `${this.basePath}/${appId}/stream/sink/${sinkId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.DELETE);
    return this.sendRequest<void>(requestContext);
  }

  async getStreamSink(appId: string, sinkId: string): Promise<StreamSinkOut> {
    const path = `${this.basePath}/${appId}/stream/sink/${sinkId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest<StreamSinkOut>(requestContext);
  }

  async listStreamSinks(appId: string): Promise<ListStreamSinksOut> {
    const path = `${this.basePath}/${appId}/stream/sink`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest<ListStreamSinksOut>(requestContext);
  }

  async updateStreamSinkTransformation(
    appId: string,
    sinkId: string,
    transformation: TransformationIn
  ): Promise<void> {
    const path = `${this.basePath}/${appId}/stream/sink/${sinkId}/transformation`;
    const requestContext = await this.getReqContext(path, HttpMethod.PATCH);
    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(transformation));
    return this.sendRequest<void>(requestContext);
  }

  async getStreamSinkTransformation(
    appId: string,
    sinkId: string
  ): Promise<TransformationOut> {
    const path = `${this.basePath}/${appId}/stream/sink/${sinkId}/transformation`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest<TransformationOut>(requestContext);
  }

  async rotateStreamSinkSigningSecret(appId: string, sinkId: string): Promise<void> {
    const path = `${this.basePath}/${appId}/stream/sink/${sinkId}/secret/rotate`;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);
    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify({}));
    return this.sendRequest<void>(requestContext);
  }

  async getStreamSinkSigningSecret(
    appId: string,
    sinkId: string
  ): Promise<StreamSinkSecretOut> {
    const path = `${this.basePath}/${appId}/stream/sink/${sinkId}/secret`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest<StreamSinkSecretOut>(requestContext);
  }

  private async sendRequest<T extends Record<string, any> | void>(
    requestContext: RequestContext
  ): Promise<T> {
    const response = await this.svix._configuration.httpApi
      .send(requestContext)
      .toPromise();

    const body = await response.body.text();
    const bodyJson: T = body.length > 0 ? JSON.parse(body) : ({} as T);
    const converted = convertDates(bodyJson);

    if (200 <= response.httpStatusCode && response.httpStatusCode < 300) {
      return converted;
    } else {
      throw converted;
    }
  }
}

// FIXME: This is a shitty way to do this.
function convertDates<T extends Record<string, any> | void>(obj: T): T {
  if (typeof obj !== "object") {
    return obj;
  }

  const dateFields = ["createdAt", "updatedAt", "deletedAt"];

  const result = { ...obj } as Record<string, any>;
  for (const [key, value] of Object.entries(result)) {
    if (typeof value === "string" && dateFields.includes(key)) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        (result as any)[key] = date;
      }
    }
  }

  return result as T;
}
