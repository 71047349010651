import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  HStack,
  Spacer,
  useToast,
  ButtonGroup,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { setErrors } from "@svix/common/formUtils";
import { formatDateTime } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import CodeEditor from "@svix/common/widgets/form/CodeEditor";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import TextField from "@svix/common/widgets/form/TextField";
import ToggleField from "@svix/common/widgets/form/Toggle";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { TransformationIn, TransformationOut } from "src/api/sinks";
import { StreamSinkOut, StreamSinkStatus } from "src/api/streamSinks";
import { useLoadingManual } from "src/utils";
import { SinkTypesMetadata } from "../constants";
import { STREAM_DEFAULT_CODE } from "../SinkCreate";
import { StreamSinkStatusNames } from "../Sinks";

interface SinkDetailsProps {
  sink: StreamSinkOut;
  queryKey: string[];
  transformationProps: Omit<TransformationEditorProps, "queryKey">;
  onUpdateSink: (sink: StreamSinkOut) => Promise<StreamSinkOut>;
  SinkProperties?: React.ReactNode;
}

export function SinkDetails(props: SinkDetailsProps) {
  const { sink, transformationProps, queryKey, SinkProperties } = props;

  const sinkMetadata = SinkTypesMetadata[sink.type];

  return (
    <>
      <Grid
        gridTemplateColumns={{
          sm: "minmax(0, 1fr)",
          md: "minmax(0, 3fr) minmax(240px, 1fr)",
        }}
        gap={8}
      >
        <Stack spacing={4}>
          <Card w="fit-content">
            <HStack>
              <Tag>{sinkMetadata.name}</Tag>
              <Divider w="5em" mx={4} />
              <Stat name="Status">
                <Stack>
                  <Tag
                    colorScheme={
                      StreamSinkStatusNames[sink.status as StreamSinkStatus].color
                    }
                    w="fit-content"
                  >
                    {StreamSinkStatusNames[sink.status as StreamSinkStatus].name}
                  </Tag>
                  {sink.disabledReason && (
                    <Text variant="caption" fontSize="sm">
                      {sink.disabledReason}
                    </Text>
                  )}
                  {sink.failureReason && (
                    <Text variant="caption" fontSize="sm">
                      {sink.failureReason}
                    </Text>
                  )}
                </Stack>
              </Stat>
            </HStack>
          </Card>
          <Tabs variant="enclosed">
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Batching</Tab>
              <Tab>Transformation</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <SinkConfiguration
                  sink={sink}
                  onUpdateSink={props.onUpdateSink}
                  queryKey={queryKey}
                />
              </TabPanel>
              <TabPanel>
                <SinkBatching
                  sink={sink}
                  onUpdateSink={props.onUpdateSink}
                  queryKey={queryKey}
                />
              </TabPanel>
              <TabPanel>
                <Card>
                  <TransformationEditor queryKey={queryKey} {...transformationProps} />
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>

        <Stack spacing={4}>
          <Stat name="Creation Date">{formatDateTime(sink.createdAt)}</Stat>
          <Divider />
          <Stat name="Last Updated">{formatDateTime(sink.updatedAt)}</Stat>
          <Divider />
          {SinkProperties}
        </Stack>
      </Grid>
    </>
  );
}

function SinkConfiguration({
  sink,
  onUpdateSink,
  queryKey,
}: {
  sink: StreamSinkOut;
  onUpdateSink: (sink: StreamSinkOut) => Promise<StreamSinkOut>;
  queryKey: string[];
}) {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();

  const formCtx = useForm({
    defaultValues: sink,
  });

  const cancelEdit = () => {
    setIsEditing(false);
    formCtx.reset(sink);
  };

  const mutation = useMutation(async (form: Partial<StreamSinkOut>) => {
    try {
      const updated = await onUpdateSink({ ...sink, ...form });
      setIsEditing(false);
      queryClient.setQueryData(queryKey, updated);
      queryClient.invalidateQueries(queryKey);
    } catch (error) {
      setErrors(formCtx.setError, error);
    }
  });

  const sinkMetadata = SinkTypesMetadata[sink.type];

  return (
    <Form
      onSubmit={(form) => mutation.mutate(form)}
      {...formCtx}
      shouldPromptOnDirty={false}
    >
      <Card
        title="Configuration"
        cta={
          isEditing ? (
            <ButtonGroup ml={2} size="xs">
              <Button colorScheme="gray" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" isLoading={mutation.isLoading}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : (
            <Button
              type="button"
              size="xs"
              colorScheme="gray"
              ml={2}
              onClick={() => setIsEditing(true)}
              key="edit"
            >
              Edit
            </Button>
          )
        }
      >
        {isEditing ? (
          <Stack spacing={5}>
            <SinkConfigForm formCtx={formCtx} type={sink.type} />
            <GeneralFormErrors />
          </Stack>
        ) : (
          <Stack spacing={4}>
            {sinkMetadata.values
              .filter(({ sensitive }) => !sensitive)
              .map(({ key, label }) => (
                <Stat key={key} name={<Text fontWeight="semibold">{label}</Text>}>
                  <code>{(sink.config as any)[key]}</code>
                </Stat>
              ))}
          </Stack>
        )}
      </Card>
    </Form>
  );
}

function SinkConfigForm({ formCtx, type }: { formCtx: any; type: string }) {
  const sinkValues = SinkTypesMetadata[type];
  return (
    <Stack spacing={5}>
      {sinkValues.values.map(({ key, label, placeholder, description, sensitive }) => (
        <TextField
          key={key}
          control={formCtx.control}
          name={`config.${key}`}
          label={label}
          placeholder={placeholder}
          required
          helperText={description}
          type={sensitive ? "password" : "text"}
        />
      ))}
    </Stack>
  );
}

interface SinkBatchingForm {
  batchSize: string;
  maxWaitSecs: string;
}

function SinkBatching({
  sink,
  onUpdateSink,
  queryKey,
}: {
  sink: StreamSinkOut;
  onUpdateSink: (sink: StreamSinkOut) => Promise<StreamSinkOut>;
  queryKey: string[];
}) {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const formCtx = useForm({
    defaultValues: {
      batchSize: sink.batchSize.toString() || "1000",
      maxWaitSecs: sink.maxWaitSecs.toString() || "10",
    },
  });

  const mutation = useMutation(async (form: SinkBatchingForm) => {
    try {
      const updated = await onUpdateSink({
        ...sink,
        batchSize: parseInt(form.batchSize),
        maxWaitSecs: parseInt(form.maxWaitSecs),
      });
      setIsEditing(false);
      queryClient.setQueryData(queryKey, updated);
      queryClient.invalidateQueries(queryKey);
    } catch (error) {
      setErrors(formCtx.setError, error);
    }
  });

  return (
    <Form
      {...formCtx}
      onSubmit={(form) => mutation.mutate(form)}
      shouldPromptOnDirty={false}
    >
      <Card
        title="Batching"
        cta={
          isEditing ? (
            <ButtonGroup ml={2} size="xs">
              <Button
                colorScheme="gray"
                onClick={() => {
                  setIsEditing(false);
                  formCtx.reset();
                }}
              >
                Cancel
              </Button>
              <SubmitButton isLoading={mutation.isLoading}>Save</SubmitButton>
            </ButtonGroup>
          ) : (
            <Button
              type="button"
              size="xs"
              colorScheme="gray"
              ml={2}
              onClick={() => setIsEditing(true)}
              key="edit"
            >
              Edit
            </Button>
          )
        }
      >
        <Text mb={4} fontSize="sm">
          Requests are batched and sent when either the batch size is reached or the
          maximum wait time is met. Whichever comes first.
        </Text>
        {isEditing ? (
          <>
            <Stack spacing={4}>
              <TextField
                control={formCtx.control}
                name="batchSize"
                label="Batch Size"
                required
                type="number"
              />
              <TextField
                control={formCtx.control}
                name="maxWaitSecs"
                label="Max Wait (seconds)"
                required
                type="number"
              />
              <GeneralFormErrors />
            </Stack>
          </>
        ) : (
          <Stack spacing={4}>
            <Stat name={<Text fontWeight="semibold">Batch Size</Text>}>
              {sink.batchSize}
            </Stat>
            <Stat name={<Text fontWeight="semibold">Max Wait (seconds)</Text>}>
              {sink.maxWaitSecs}
            </Stat>
          </Stack>
        )}
      </Card>
    </Form>
  );
}

interface TransformationEditorProps {
  transformation: TransformationOut;
  queryKey: string[];
  onUpdateTransformation: (transformation: TransformationIn) => Promise<void>;
}

function TransformationEditor({
  transformation,
  queryKey,
  onUpdateTransformation,
}: TransformationEditorProps) {
  const { enabled, code } = transformation;
  const toast = useToast();
  const queryClient = useQueryClient();
  const formCtx = useForm({
    defaultValues: {
      code: code ?? STREAM_DEFAULT_CODE,
      enabled: enabled,
    },
  });
  const { watch, setValue } = formCtx;

  const newCode = watch("code");
  const isEnabled = watch("enabled");

  const [isSubmitting, , updateTransformation] = useLoadingManual(
    async (form) => {
      try {
        if (form.code.length === 0) {
          form.code = null;
          form.enabled = false;
        }

        await onUpdateTransformation(form);

        toast({
          title: "Transformation saved",
          description: "The transformation has been updated successfully",
          status: "success",
        });

        formCtx.reset({ code: form.code, enabled: form.enabled });
        queryClient.invalidateQueries([...queryKey, "transformation"]);
      } catch (error) {
        setErrors(formCtx.setError, error);
      }
    },
    [onUpdateTransformation]
  );

  useEffect(() => {
    if (!code) {
      setValue("code", STREAM_DEFAULT_CODE);
    }
  }, [isEnabled, code, setValue]);

  return (
    <Stack>
      <Form
        {...formCtx}
        onSubmit={(form) => updateTransformation(form)}
        shouldPromptOnDirty={false}
      >
        <HStack justifyContent="space-between" w="100%">
          <Box>
            <Heading size="sm">Transformation function</Heading>
            <Text variant="caption" fontSize="sm">
              Transform the event before it is sent to the destination.
            </Text>
          </Box>
          <Box>
            <ToggleField control={formCtx.control} name="enabled" label="Enabled" />
          </Box>
        </HStack>
        {formCtx.watch("enabled") && (
          <Box mt={4}>
            <CodeEditor
              lang={Lang.Javascript}
              value={newCode}
              dark
              onChange={(value) => setValue("code", value)}
            />
          </Box>
        )}
        <Stack spacing={2}>
          <GeneralFormErrors />
          <HStack>
            <Spacer />
            {(formCtx.formState.isDirty || code !== newCode) && (
              <ButtonGroup mt={2}>
                <Button colorScheme="gray" onClick={() => formCtx.reset()}>
                  Cancel
                </Button>
                <SubmitButton colorScheme="brand" isLoading={isSubmitting}>
                  Save
                </SubmitButton>
              </ButtonGroup>
            )}
          </HStack>
        </Stack>
      </Form>
    </Stack>
  );
}
