import {
  Checkbox,
  Collapse,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  EndpointApi,
  EndpointMtlsConfigIn,
  EndpointMtlsConfigOut,
} from "svix/dist/openapi";

import { setErrors } from "@svix/common/formUtils";
import { getApiErrorCode, humanize } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextAreaField from "@svix/common/widgets/form/TextArea";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";

const DEFAULT_VALUES = {
  identity: "",
  serverCaCert: "",
};

export default function EndpointMtls() {
  const user = useAppSelector((state) => state.auth.user)!;

  const { endpId } = useParams<{ endpId: string }>();
  const queryKey = ["endpoints", endpId, "mtls"];

  const { data, isLoading } = useAppQuery(queryKey, async () => {
    const sv = getSvix();
    const api = new EndpointApi(sv._configuration);
    try {
      const res = await api.v1EndpointGetMtlsConfig({
        appId: user.app.id,
        endpointId: endpId,
      });
      return res;
    } catch (error) {
      if (getApiErrorCode(error) === "not_found") {
        return undefined;
      }
      throw error;
    }
  });

  return (
    <>
      <MetaTitle path={["mTLS"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItem
            to={`${routeResolver.getRoute("endpoints._id", { endpId })}?tab=advanced`}
          >
            {humanize(endpId)}
          </BreadcrumbItem>
          <BreadcrumbItem>mTLS</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Card maxW="50em">
        <Heading size="md">mTLS Configuration</Heading>
        <Text fontSize="sm" mt={1}>
          Configure mutual TLS (mTLS) to verify the identity of both the client and server
          before sending a webhook.
        </Text>
        <Skeleton isLoaded={!isLoading} minH="16em">
          <EndpointMtlsForm mtlsConfig={data || DEFAULT_VALUES} />
        </Skeleton>
      </Card>
    </>
  );
}

interface IEndpointMtlsFormProps {
  mtlsConfig: EndpointMtlsConfigOut;
}

const SERVER_CA_CERT_PLACEHOLDER = `-----BEGIN CERTIFICATE-----
MIIDbTCCAlWgAwIBAgIUPcs...
-----END CERTIFICATE-----`;
const IDENTITY_PLACEHOLDER = `-----BEGIN PRIVATE KEY-----
MIIDhjCCAm6gAwIBAgIUZas...
-----END PRIVATE KEY-----

-----BEGIN CERTIFICATE-----
MIIEuwIBADANBgkqhkiG9w0...
-----END CERTIFICATE-----`;

type EndpointMtlsConfigForm = EndpointMtlsConfigIn & { useCustomCaCert: boolean };

function EndpointMtlsForm({ mtlsConfig }: IEndpointMtlsFormProps) {
  const { endpId } = useParams<{ endpId: string }>();
  const history = useHistory();
  const toast = useToast();

  const user = useAppSelector((state) => state.auth.user)!;
  const queryClient = useQueryClient();
  const queryKey = ["endpoints", endpId, "mtls"];
  const formCtx = useForm<EndpointMtlsConfigForm>({
    defaultValues: {
      identity: "",
      serverCaCert: mtlsConfig.serverCaCert,
      useCustomCaCert: Boolean(mtlsConfig.serverCaCert),
    },
  });
  const useCustomCaCert = formCtx.watch("useCustomCaCert");

  const onUpdateMtlsConfig = useMutation(async (form: EndpointMtlsConfigForm) => {
    const sv = getSvix();
    const api = new EndpointApi(sv._configuration);

    try {
      const configIn = { ...form };
      if (!form.useCustomCaCert) {
        delete configIn.serverCaCert;
      }
      await api.v1EndpointUpdateMtlsConfig({
        appId: user.app.id,
        endpointId: endpId,
        endpointMtlsConfigIn: configIn,
      });

      toast({
        title: "mTLS configuration updated",
        description: "The mTLS configuration has been updated successfully.",
        status: "success",
      });

      queryClient.invalidateQueries(queryKey);
      history.push(`${routeResolver.getRoute("endpoints._id", { endpId })}?tab=advanced`);
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  });

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  const onCancel = () => {
    formCtx.reset();
    history.push(`${routeResolver.getRoute("endpoints._id", { endpId })}?tab=advanced`);
  };

  return (
    <Form
      onSubmit={(val) => onUpdateMtlsConfig.mutate(val)}
      {...formCtx}
      shouldPromptOnDirty={false}
    >
      <Stack spacing={4} mt={4}>
        <TextAreaField
          label="Identity"
          helperText="A PEM encoded private key and X509 certificate to sign the webhooks."
          name="identity"
          placeholder={IDENTITY_PLACEHOLDER}
          required
          control={formCtx.control}
          isDisabled={isReadOnly}
        />
        <Checkbox
          name="useCustomCaCert"
          isChecked={useCustomCaCert}
          onChange={() => formCtx.setValue("useCustomCaCert", !useCustomCaCert)}
        >
          Use custom Certificate Authority certificate
        </Checkbox>
        <Collapse in={useCustomCaCert} animateOpacity>
          <TextAreaField
            label="Receiving Server CA Certificate"
            helperText="A PEM encoded X509 certificate of the receiving server."
            name="serverCaCert"
            placeholder={SERVER_CA_CERT_PLACEHOLDER}
            required={useCustomCaCert}
            control={formCtx.control}
            isDisabled={isReadOnly}
          />
        </Collapse>
        <GeneralFormErrors />
        <HStack
          justifyContent="flex-end"
          hidden={!formCtx.formState.isDirty || isReadOnly}
        >
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <SubmitButton isLoading={onUpdateMtlsConfig.isLoading}>Save</SubmitButton>
        </HStack>
      </Stack>
    </Form>
  );
}
