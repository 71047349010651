import { useEffect } from "react";
import {
  FormLabel,
  FormControl,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  useToast,
  FormHelperText,
} from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  EndpointApi,
  EndpointOauthConfigOut,
  Oauth2AuthMethodInOut,
  Oauth2GrantTypeInOut,
  OauthJwsSigningAlgorithm,
} from "svix/dist/openapi";

import { setErrors } from "@svix/common/formUtils";
import { getApiErrorCode, humanize } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import SelectField from "@svix/common/widgets/form/Select";
import TextField from "@svix/common/widgets/form/TextField";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";

const DEFAULT_VALUES = {
  grantType: Oauth2GrantTypeInOut.ClientCredentials,
  authMethod: Oauth2AuthMethodInOut.ClientSecretBasic,
  clientId: "",
  clientSecret: "",
  tokenUrl: "",
  refreshToken: "",
  jwtParams: {
    secretBase64: "",
    signingAlgorithm: OauthJwsSigningAlgorithm.Rs256,
    secretId: "",
  },
  scopes: [],
};

export default function EndpointOAuth() {
  const user = useAppSelector((state) => state.auth.user)!;

  const { endpId } = useParams<{ endpId: string }>();
  const queryKey = ["endpoints", endpId, "oauth"];

  const { data, isLoading } = useAppQuery(queryKey, async () => {
    const sv = getSvix();
    const api = new EndpointApi(sv._configuration);
    try {
      const res = await api.v1EndpointGetOauthConfig({
        appId: user.app.id,
        endpointId: endpId,
      });
      return res;
    } catch (error) {
      if (getApiErrorCode(error) === "not_found") {
        return undefined;
      }
      throw error;
    }
  });

  return (
    <>
      <MetaTitle path={["OAuth"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItem
            to={`${routeResolver.getRoute("endpoints._id", { endpId })}?tab=advanced`}
          >
            {humanize(endpId)}
          </BreadcrumbItem>
          <BreadcrumbItem>OAuth</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Card maxW="50em">
        <Heading size="md">OAuth Configuration</Heading>
        <Text fontSize="sm" mt={1}>
          Use your own OAuth 2.0 provider to authenticate webhooks.
        </Text>
        <Skeleton isLoaded={!isLoading} minH="20em">
          <EndpointOAuthForm oauthConfig={data || DEFAULT_VALUES} />
        </Skeleton>
      </Card>
    </>
  );
}

interface IEndpointOAuthFormProps {
  oauthConfig: EndpointOauthConfigOut;
}

interface OAuthForm {
  grantType: Oauth2GrantTypeInOut;
  authMethod: Oauth2AuthMethodInOut;
  clientId: string;
  clientSecret: string;
  tokenUrl: string;
  refreshToken: string;
  jwtParams?: {
    secretBase64: string;
    signingAlgorithm: OauthJwsSigningAlgorithm;
    secretId: string;
    tokenExpirySecs: number;
  };
  scopes: string[];
}

function EndpointOAuthForm({ oauthConfig }: IEndpointOAuthFormProps) {
  const { endpId } = useParams<{ endpId: string }>();
  const history = useHistory();
  const toast = useToast();

  const user = useAppSelector((state) => state.auth.user)!;
  const queryClient = useQueryClient();
  const queryKey = ["endpoints", endpId, "oauth"];
  const formCtx = useForm<OAuthForm>({
    defaultValues: {
      ...oauthConfig,
      scopes: oauthConfig.scopes || [],
    },
  });

  const onUpdateOauthConfig = useMutation(async (form: OAuthForm) => {
    const sv = getSvix();
    const api = new EndpointApi(sv._configuration);

    if (!needsJWTParams(form.authMethod)) {
      delete form.jwtParams;
    }

    const scopes = form.scopes.map((s) => s.trim());

    try {
      await api.v1EndpointUpdateOauthConfig({
        appId: user.app.id,
        endpointId: endpId,
        endpointOauthConfigIn: {
          grantType: form.grantType,
          authMethod: form.authMethod,
          clientId: form.clientId,
          clientSecret: form.clientSecret,
          tokenUrl: form.tokenUrl,
          refreshToken: form.refreshToken,
          jwtParams: form.jwtParams,
          scopes: scopes.length > 0 ? scopes : undefined,
        },
      });

      toast({
        title: "OAuth configuration updated",
        description: "The OAuth configuration has been updated successfully.",
        status: "success",
      });

      queryClient.invalidateQueries(queryKey);
      history.push(`${routeResolver.getRoute("endpoints._id", { endpId })}?tab=advanced`);
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  });

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  const onCancel = () => {
    formCtx.reset();
    history.push(`${routeResolver.getRoute("endpoints._id", { endpId })}?tab=advanced`);
  };

  const authMethod = formCtx.watch("authMethod");
  useEffect(() => {
    if (authMethod === Oauth2AuthMethodInOut.ClientSecretJwt) {
      formCtx.setValue("grantType", Oauth2GrantTypeInOut.ClientCredentials);
      formCtx.setValue("jwtParams.signingAlgorithm", OauthJwsSigningAlgorithm.Rs256);
    }
  }, [formCtx, authMethod]);

  // FIXME: Adds links to RFCs or docs?
  return (
    <Form
      onSubmit={(val) => onUpdateOauthConfig.mutate(val)}
      {...formCtx}
      shouldPromptOnDirty={false}
    >
      <Stack spacing={4} mt={4}>
        <SelectField
          autoFocus
          name="grantType"
          label="Grant Type"
          control={formCtx.control}
          isDisabled={isReadOnly}
        >
          <option value={Oauth2GrantTypeInOut.ClientCredentials}>
            Client Credentials
          </option>
          {/* Refresh Token is not available for Client Secret JWT */}
          {authMethod !== Oauth2AuthMethodInOut.ClientSecretJwt && (
            <option value={Oauth2GrantTypeInOut.RefreshToken}>Refresh Token</option>
          )}
        </SelectField>
        <SelectField
          name="authMethod"
          label="Authentication Method"
          control={formCtx.control}
          isDisabled={isReadOnly}
        >
          <option value={Oauth2AuthMethodInOut.ClientSecretBasic}>
            Client Secret Basic
          </option>
          <option value={Oauth2AuthMethodInOut.ClientSecretPost}>
            Client Secret Post
          </option>
          <option value={Oauth2AuthMethodInOut.ClientSecretJwt}>Client Secret JWT</option>
        </SelectField>
        <TextField
          name="tokenUrl"
          label="Authorization Server URL"
          type="text"
          helperText="The URL of the authorization server that will issue the OAuth 2.0 tokens."
          placeholder="https://example.com/oauth/token"
          required
          control={formCtx.control}
          isDisabled={isReadOnly}
        />
        <TextField
          label="Client ID"
          name="clientId"
          placeholder="my-client-id"
          type="text"
          required
          control={formCtx.control}
          isDisabled={isReadOnly}
        />
        {needsClientSecret(formCtx.watch("authMethod")) && (
          <TextField
            label="Client Secret"
            name="clientSecret"
            type="password"
            autoComplete="off"
            placeholder="••••••••"
            required
            control={formCtx.control}
            isDisabled={isReadOnly}
          />
        )}
        {needsRefreshToken(formCtx.watch("grantType")) && (
          <TextField
            label="Refresh Token"
            name="refreshToken"
            type="password"
            autoComplete="off"
            placeholder="••••••••"
            required
            control={formCtx.control}
            isDisabled={isReadOnly}
          />
        )}
        {needsJWTParams(formCtx.watch("authMethod")) && (
          <>
            <TextField
              label="JWT Signing Secret (Base64)"
              name="jwtParams.secretBase64"
              type="password"
              autoComplete="off"
              helperText="The base64-encoded secret used for signing the JWT."
              required
              control={formCtx.control}
              isDisabled={isReadOnly}
            />
            <SelectField
              name="jwtParams.signingAlgorithm"
              label="Signing Algorithm"
              control={formCtx.control}
              isDisabled={isReadOnly}
            >
              <option value={OauthJwsSigningAlgorithm.Rs256}>RS256</option>
            </SelectField>
            <TextField
              label="Secret ID"
              name="jwtParams.secretId"
              type="text"
              helperText="Optional. If supplied, this will be populated in the JWT header in the `kid` field"
              control={formCtx.control}
              isDisabled={isReadOnly}
            />
          </>
        )}
        <Controller
          name="scopes"
          control={formCtx.control}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <FormLabel>Scopes</FormLabel>
              <CreatableSelect
                components={{
                  DropdownIndicator: null,
                }}
                isMulti
                value={value.map((scope) => ({ label: scope, value: scope }))}
                onChange={(newValue) => onChange(newValue.map((item) => item.value))}
                placeholder="Add scopes..."
                noOptionsMessage={() => "Type to add scopes"}
                formatCreateLabel={(input) => `Add "${input}" as scope`}
                isDisabled={isReadOnly}
                isClearable
              />
              <FormHelperText>
                OAuth scopes to request from the authorization server.
              </FormHelperText>
            </FormControl>
          )}
        />
        <GeneralFormErrors />
        <HStack
          justifyContent="flex-end"
          hidden={!formCtx.formState.isDirty || isReadOnly}
        >
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <SubmitButton isLoading={onUpdateOauthConfig.isLoading}>Save</SubmitButton>
        </HStack>
      </Stack>
    </Form>
  );
}

function needsClientSecret(authMethod: Oauth2AuthMethodInOut) {
  return (
    authMethod === Oauth2AuthMethodInOut.ClientSecretBasic ||
    authMethod === Oauth2AuthMethodInOut.ClientSecretPost
  );
}

function needsRefreshToken(grantType: Oauth2GrantTypeInOut) {
  return grantType === Oauth2GrantTypeInOut.RefreshToken;
}

function needsJWTParams(authMethod: Oauth2AuthMethodInOut) {
  return authMethod === Oauth2AuthMethodInOut.ClientSecretJwt;
}
