import { formatDateTime } from "@svix/common/utils";
import RadioButtons from "@svix/common/widgets/form/RadioButtons";

import { ONE_DAY, ONE_HOUR } from "./index";

interface IRecoverRadioProps {
  isDisabled?: boolean;
  control: any;
  name: string;
}

export type RecoverTime =
  | "8-hours-ago"
  | "yesterday"
  | "3-days-ago"
  | "last-week"
  | "2-weeks-ago";

export function getDate(recoverTime: RecoverTime): Date {
  const now = new Date().getTime();
  switch (recoverTime) {
    case "yesterday": {
      const yesterday = new Date(now - ONE_DAY);
      return yesterday;
    }
    case "last-week": {
      const lastWeek = new Date(now - 7 * ONE_DAY);
      return lastWeek;
    }
    case "3-days-ago": {
      const threeDaysAgo = new Date(now - 3 * ONE_DAY);
      return threeDaysAgo;
    }
    case "2-weeks-ago": {
      const twoWeeksAgo = new Date(now - 14 * ONE_DAY);
      return twoWeeksAgo;
    }
    case "8-hours-ago":
    default:
      return new Date(now - ONE_HOUR * 8);
  }
}

export default function RecoverRadio(props: IRecoverRadioProps) {
  const buttons = Object.freeze([
    {
      title: "8 hours ago",
      description: `Since ${formatDateTime(getDate("8-hours-ago"))}`,
      value: "8-hours-ago" as RecoverTime,
    },
    {
      title: "Yesterday",
      description: `Since ${formatDateTime(getDate("yesterday"))}`,
      value: "yesterday" as RecoverTime,
    },
    {
      title: "3 days ago",
      description: `Since ${formatDateTime(getDate("3-days-ago"))}`,
      value: "3-days-ago" as RecoverTime,
    },
    {
      title: "Last week",
      description: `Since ${formatDateTime(getDate("last-week"))}`,
      value: "last-week" as RecoverTime,
    },
    {
      title: "2 weeks ago",
      description: `Since ${formatDateTime(getDate("2-weeks-ago"))}`,
      value: "2-weeks-ago" as RecoverTime,
    },
  ]);

  return <RadioButtons<RecoverTime> buttons={buttons} {...props} />;
}
