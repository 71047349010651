import {
  Heading,
  Avatar,
  Divider,
  Flex,
  List,
  ListItem,
  Link as StyledLink,
  useColorModeValue,
} from "@chakra-ui/react";
import { Code, History, TrackChanges, Category, Extension } from "@material-ui/icons";

import * as C from "@svix/common/constants";
import Sidebar, { SidebarItem, SidebarIcon } from "@svix/common/widgets/Sidebar";

import useFeatureFlag from "src/hooks/featureFlag";
import { useAppSelector } from "src/hooks/store";
import { isEE } from "src/utils";
import { routeResolver } from "../App";
import svixLogo from "../logo.svg";

export const drawerWidth = 240;

interface IDrawerProps {
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

const svixUrl = new URL(C.homePage);
svixUrl.searchParams.append("utm_source", "app_portal");
svixUrl.searchParams.append("utm_medium", "watermark");
svixUrl.searchParams.append("utm_campaign", "virality");

export default function Drawer(props: IDrawerProps) {
  const { logoUrl = svixLogo, displayName } = useAppSelector(
    (state) => state.embedConfig
  );
  const { type, auth } = useAppSelector((state) => state);

  return (
    <Sidebar {...props}>
      <Flex h="100%" flexDir="column">
        <List w="100%">
          <ListItem
            p={3}
            height="64px"
            display="flex"
            alignItems="center"
            fontWeight={500}
          >
            <Avatar src={logoUrl} size="sm" mr={3} bgColor="background.secondary" />
            <Heading as="div" size="sm" isTruncated>
              {displayName || auth.user?.app.name}
            </Heading>
          </ListItem>
        </List>
        <Divider />

        {type === "stream" ? <StreamDrawer /> : <WebhooksDrawer />}

        {!isEE && (
          <Flex mt="auto" justifyContent="center" p={4}>
            <StyledLink target="_blank" href={svixUrl.toString()}>
              Powered by Svix
            </StyledLink>
          </Flex>
        )}
      </Flex>
    </Sidebar>
  );
}

function WebhooksDrawer() {
  const { hideEventTypes } = useAppSelector((state) => state.embedConfig);
  const integrationsEnabled = useFeatureFlag("integrations");
  const matchColor = useColorModeValue("navigation.accent", "navigation.accent");

  return (
    <>
      <List w="100%" p={2}>
        <SidebarItem
          name="activity"
          to={routeResolver.getRoute("activity")}
          matchColor={matchColor}
        >
          <SidebarIcon>
            <TrackChanges />
          </SidebarIcon>
          Activity
        </SidebarItem>
      </List>
      <Divider />
      <List w="100%" p={2}>
        <SidebarItem
          name="endpoints"
          to={routeResolver.getRoute("endpoints")}
          matchColor={matchColor}
        >
          <SidebarIcon>
            <Code />
          </SidebarIcon>
          Endpoints
        </SidebarItem>
        {!hideEventTypes && (
          <SidebarItem
            name="event-types"
            to={routeResolver.getRoute("event-types")}
            matchColor={matchColor}
            id="event-catalog-nav-item"
          >
            <SidebarIcon>
              <Category />
            </SidebarIcon>
            Event Catalog
          </SidebarItem>
        )}
        <SidebarItem
          name="logs"
          to={routeResolver.getRoute("messages")}
          matchColor={matchColor}
          id="logs-nav-item"
        >
          <SidebarIcon>
            <History />
          </SidebarIcon>
          Logs
        </SidebarItem>
        {integrationsEnabled && (
          <SidebarItem
            name="integrations"
            to={routeResolver.getRoute("integrations")}
            matchColor={matchColor}
          >
            <SidebarIcon>
              <Extension />
            </SidebarIcon>
            Integrations
          </SidebarItem>
        )}
      </List>
    </>
  );
}

function StreamDrawer() {
  const matchColor = useColorModeValue("navigation.accent", "navigation.accent");

  return (
    <>
      <List w="100%" p={2}>
        <SidebarItem
          name="sinks"
          to={routeResolver.getRoute("stream.sinks")}
          matchColor={matchColor}
        >
          <SidebarIcon>
            <Code />
          </SidebarIcon>
          Sinks
        </SidebarItem>
      </List>
    </>
  );
}
