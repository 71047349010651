import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { useAppDispatch } from "src/hooks/store";
import { pushError } from "./store/errors";

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function useLoadingManual<T>(
  func_: (...args: any[]) => Promise<T>,
  deps: React.DependencyList
): [boolean, T | undefined, (...args: any[]) => Promise<T | undefined>] {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState<T>();

  const func = React.useCallback(
    async (...args: any[]): Promise<T | undefined> => {
      setLoading(true);
      try {
        const ret = await func_(...args);
        setValue(ret);
        return ret;
      } catch (e) {
        dispatch(pushError(e));
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    deps // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [loading, value, func];
}

export function useLoading<T>(
  func_: () => Promise<T>,
  deps: React.DependencyList
): [T | undefined, () => Promise<T | undefined>] {
  const ret = useLoadingManual(func_, deps);
  const value = ret[1];
  const func = ret[2];

  React.useEffect(() => {
    func();
  }, [func]);

  return [value, func];
}

export function useIdsObserver(elementIds: string[]) {
  const observer = useRef<IntersectionObserver>();
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    const handleObsever = (entries: IntersectionObserverEntry[]) => {
      const intersectingEntry = entries.find((entry) => entry.isIntersecting);
      if (intersectingEntry) {
        setActiveId(intersectingEntry.target.id);
      }
    };

    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: "0px 0px -85% 0px",
    });

    elementIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        observer.current?.observe(element);
      }
    });

    return () => observer.current?.disconnect();
  }, [elementIds]);

  return activeId;
}

export const isEE = import.meta.env.MODE === "ee";

export function getTypedNestedValue<T extends object>(obj: T, path: string): any {
  return path
    .split(".")
    .reduce(
      (current, key) =>
        current && current[key] !== undefined ? current[key] : undefined,
      obj as any
    );
}
