import { lazy, Suspense, useState } from "react";
import {
  Box,
  Collapse,
  Stack,
  HStack,
  Skeleton,
  Text,
  Spacer,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForwardIos } from "@material-ui/icons";
import { Steps, Step } from "chakra-ui-steps";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import EventsList from "@svix/common/widgets/EventsList";
import Form from "@svix/common/widgets/Form";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import SelectField from "@svix/common/widgets/form/Select";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { StreamSinkIn } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { SinkTypesMetadata } from "../constants";

const CodeEditor = lazy(() => import("@svix/common/widgets/form/CodeEditor"));

export default function StreamSinkCreateScreen() {
  const DEFAULT_VALUES = {
    batchSize: 100,
    maxWaitSecs: 10,
    transformation: STREAM_DEFAULT_CODE,
  };

  const [sinkConfig, setSinkConfig] = useState<Partial<StreamSinkIn>>(DEFAULT_VALUES);
  const [activeStep, setActiveStep] = useState(0);

  const onNext = (
    data: FormSinkConfiguration | FormSinkEventTypes | FormSinkTransformation
  ) => {
    setActiveStep(activeStep + 1);
    setSinkConfig({
      ...sinkConfig,
      ...data,
    });
  };

  const onSubmit = async (data: FormSinkBatching) => {
    const sinkIn: StreamSinkIn = {
      ...(sinkConfig as StreamSinkIn),
      ...data,
    };
    // FIXME STREAM_APP_PORTAL: Implement
    // eslint-disable-next-line no-console
    console.log(sinkIn);
  };

  const StepTitles = [
    "Configure connection",
    "Select event types",
    "Write transformation",
    "Configure batching",
  ];

  return (
    <>
      <MetaTitle path={["New Sink"]} />
      <PageToolbar>
        <HStack maxW="60em" justifyContent="space-between" w="100%">
          <Breadcrumbs>
            <BreadcrumbItem to={routeResolver.getRoute("stream.sinks")}>
              Sinks
            </BreadcrumbItem>
            <BreadcrumbItem>New Sink</BreadcrumbItem>
          </Breadcrumbs>
        </HStack>
      </PageToolbar>

      <HStack spacing={2} h="fit-content" alignItems="flex-start">
        <Box minW="14em">
          <Steps activeStep={activeStep} orientation="vertical" gap={4}>
            <Step label="Step 1" description={StepTitles[0]} />
            <Step label="Step 2" description={StepTitles[1]} />
            <Step label="Step 3" description={StepTitles[2]} />
            <Step label="Step 4" description={StepTitles[3]} />
          </Steps>
        </Box>
        <Box w="100%" maxW="50em">
          <Box h="100%" borderRadius="md">
            {activeStep === 0 && (
              <SinkConfigurationStep
                defaultValues={sinkConfig as FormSinkConfiguration}
                onSubmit={(d) => {
                  setSinkConfig({ ...DEFAULT_VALUES, ...d });
                  onNext(d);
                }}
              />
            )}
            {activeStep === 1 && (
              <SinkEventTypesStep
                defaultValues={sinkConfig as FormSinkEventTypes}
                onSubmit={onNext}
                onPrev={() => setActiveStep(activeStep - 1)}
              />
            )}
            {activeStep === 2 && (
              <SinkTransformationStep
                defaultValues={sinkConfig as FormSinkTransformation}
                onSubmit={onNext}
                onPrev={() => setActiveStep(activeStep - 1)}
              />
            )}
            {activeStep === 3 && (
              <SinkBatchingStep
                defaultValues={sinkConfig as FormSinkBatching}
                onSubmit={onSubmit}
                onPrev={() => setActiveStep(activeStep - 1)}
              />
            )}
          </Box>
        </Box>
      </HStack>
    </>
  );
}

// FIXME STREAM_APP_PORTAL: Remove any
function SinkConfigFormInner({ formCtx, type }: { formCtx: any; type: string }) {
  const sinkValues = SinkTypesMetadata[type];
  return (
    <Stack spacing={5}>
      {sinkValues.values.map(({ key, label, placeholder, description, sensitive }) => (
        <TextField
          key={key}
          control={formCtx.control}
          name={`config.${key}`}
          label={label}
          placeholder={placeholder}
          required
          helperText={description}
          type={sensitive ? "password" : "text"}
        />
      ))}
    </Stack>
  );
}

type FormSinkConfiguration = {
  type: string;
  config: any;
};

function SinkConfigurationStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues: FormSinkConfiguration;
  onSubmit: (data: FormSinkConfiguration) => void;
}) {
  const formCtx = useForm({
    defaultValues,
    shouldUnregister: true,
  });
  const { watch } = formCtx;
  const type = watch("type");

  return (
    <Form {...formCtx} onSubmit={onSubmit}>
      <Stack spacing={5}>
        <SelectField
          label="Connection type"
          name="type"
          control={formCtx.control}
          isRequired
        >
          <option value="" disabled selected>
            Select...
          </option>
          {Object.keys(SinkTypesMetadata).map((type) => (
            <option key={type} value={type}>
              {SinkTypesMetadata[type].name}
            </option>
          ))}
        </SelectField>
        <Collapse in={!!type}>
          <Card bg="transparent">
            {type && <SinkConfigFormInner formCtx={formCtx} type={type} />}
          </Card>
        </Collapse>
      </Stack>
      <HStack mt={4}>
        <Spacer />
        <Button type="submit">Next</Button>
      </HStack>
    </Form>
  );
}

type FormSinkEventTypes = {
  eventTypes: string[];
};

function SinkEventTypesStep({
  defaultValues,
  onSubmit,
  onPrev,
}: {
  defaultValues: FormSinkEventTypes;
  onSubmit: (data: FormSinkEventTypes) => void;
  onPrev: () => void;
}) {
  const formCtx = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  return (
    <Form {...formCtx} onSubmit={onSubmit}>
      <EventsList
        label={
          <Flex alignItems="center" justifyContent="space-between">
            <span>Select event types to subscribe to</span>
            <StyledLink
              fontSize="sm"
              display="flex"
              alignItems="center"
              color="interactive.accent"
              to={routeResolver.getRoute("event-types")}
            >
              Event Catalog
              <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
            </StyledLink>
          </Flex>
        }
        availableEvents={[
          {
            name: "user.created",
            description: "User created",
            deprecated: false,
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        ]}
        control={formCtx.control}
        name="eventTypes"
        emptyState="Receiving all events."
      />
      <HStack mt={4}>
        <Spacer />
        <Button variant="outline" colorScheme="gray" onClick={onPrev}>
          Back
        </Button>
        <Button type="submit">Next</Button>
      </HStack>
    </Form>
  );
}

type FormSinkTransformation = {
  transformation: string;
};

function SinkTransformationStep({
  defaultValues,
  onSubmit,
  onPrev,
}: {
  defaultValues: FormSinkTransformation;
  onSubmit: (data: FormSinkTransformation) => void;
  onPrev: () => void;
}) {
  const formCtx = useForm({ defaultValues, shouldUnregister: true });

  const { watch, setValue } = formCtx;
  return (
    <Form {...formCtx} onSubmit={onSubmit}>
      <Suspense fallback={<Skeleton h="12em" />}>
        <Card bg="transparent">
          <Heading size="md">Transformation</Heading>
          <Text mb={4}>Transform the event before it is sent to the destination.</Text>

          <CodeEditor
            lang={Lang.Javascript}
            value={watch("transformation")}
            onChange={(code) => setValue("transformation", code)}
            dark
          />
        </Card>
      </Suspense>
      <HStack mt={4}>
        <Spacer />
        <Button variant="outline" colorScheme="gray" onClick={onPrev}>
          Back
        </Button>
        <Button type="submit">Next</Button>
      </HStack>
    </Form>
  );
}

type FormSinkBatching = {
  batchSize: number;
  maxWaitSecs: number;
};

function SinkBatchingStep({
  defaultValues,
  onSubmit,
  onPrev,
}: {
  defaultValues: FormSinkBatching;
  onSubmit: (data: FormSinkBatching) => void;
  onPrev: () => void;
}) {
  const formCtx = useForm({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(
      yup.object({
        batchSize: yup
          .number()
          .min(1, "Batch size must be at least 1")
          .max(50000, "Batch size cannot exceed 50000")
          .required(),
        maxWaitSecs: yup
          .number()
          .min(10, "Maximum wait time must be at least 10 seconds")
          .max(500, "Maximum wait time cannot exceed 500 seconds")
          .required(),
      })
    ),
  });

  return (
    <Form {...formCtx} onSubmit={onSubmit}>
      <Card w="100%" bg="transparent">
        <Stack spacing={4}>
          <Heading size="md">Event Batching</Heading>
          <TextField
            control={formCtx.control}
            name="batchSize"
            label="Batch size"
            placeholder="100"
            helperText="The number of events to send in each batch."
            type="number"
          />
          <TextField
            control={formCtx.control}
            name="maxWaitSecs"
            label="Maximum batch wait time (seconds)"
            placeholder="10"
            helperText="The maximum amount of time to wait for events before sending a batch."
            type="number"
          />
        </Stack>
      </Card>
      <HStack mt={4}>
        <Spacer />
        <Button variant="outline" colorScheme="gray" onClick={onPrev}>
          Back
        </Button>
        <Button
          colorScheme="green"
          type="submit"
          isLoading={formCtx.formState.isSubmitting}
        >
          Create
        </Button>
      </HStack>
    </Form>
  );
}

export const STREAM_DEFAULT_CODE = `/**
 * @param input - The input object
 * @param input.events - The array of messages in the batch
 * @param input.events[].payload - The message payload
 * @param input.events[].eventType - The message event type
 * 
 * @returns Object containing the request body
 * @returns returns.requestBody - The body that will be sent to the endpoint
 */
function handler(input) {
  const events = input.events.map((evt) => ({
    payload: evt.payload,
    eventType: evt.eventType
  }));

  return {
    requestBody: JSON.stringify({ data: events })
  }
}
`;
