import { useState } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Skeleton,
  Text,
  useBoolean,
  Modal,
  Box,
  Spacer,
  Flex,
  ModalFooter,
  HStack,
  Tooltip,
  Stack,
  Code,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { FileCopy } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { AuthenticationApi, AuthTokenOut } from "svix/dist/openapi";

import useCopyToClipboard from "@svix/common/hooks/copyToClipboard";
import { formatDateTime } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import SelectField from "@svix/common/widgets/form/Select";
import { ModalOverlay, ModalContent } from "@svix/common/widgets/Modal";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppQuery } from "src/hooks/api";
import { useAppIdSelector, useAppSelector } from "src/hooks/store";
import { useLoadingManual } from "src/utils";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";

export default function EndpointToken({ endpointId }: { endpointId: string }) {
  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);
  const [isEndpointCreateKeyOpen, setIsEndpointCreateKeyOpen] = useBoolean();
  const [isEndpointRotateKeyOpen, setIsEndpointRotateKeyOpen] = useBoolean();
  const queryKey = ["endpoints", "sinks", "polling", endpointId, "apikey"];
  const appId = useAppIdSelector()!;

  const { data: apiKey, isLoading } = useAppQuery(queryKey, async () => {
    const svix = getSvix();
    const api = new AuthenticationApi(svix._configuration);
    const key = await api.v1AuthenticationGetPollerToken({ appId, endpointId });
    return key;
  });

  const hasKey = !!apiKey && !isLoading;

  return (
    <Stat
      id="stat-endpoint-token"
      name="Endpoint API Key"
      cta={
        apiKey && (
          <ReadOnlyTooltip readOnly={isReadOnly}>
            <Button
              isDisabled={isReadOnly}
              type="button"
              size="xs"
              colorScheme="gray"
              ml={2}
              onClick={setIsEndpointRotateKeyOpen.on}
              key="edit"
            >
              Rotate
            </Button>
          </ReadOnlyTooltip>
        )
      }
    >
      <Skeleton isLoaded={!isLoading} fadeDuration={0.5}>
        {hasKey ? (
          <Text as="span" fontFamily="mono">
            {apiKey.token}
          </Text>
        ) : (
          <Button size="xs" colorScheme="brand" onClick={setIsEndpointCreateKeyOpen.on}>
            Create API Key
          </Button>
        )}
      </Skeleton>

      <RotateEndpointToken
        isOpen={isEndpointRotateKeyOpen}
        onClose={setIsEndpointRotateKeyOpen.off}
        queryKey={queryKey}
        endpointId={endpointId}
      />
      <NewEndpointToken
        isOpen={isEndpointCreateKeyOpen}
        onClose={setIsEndpointCreateKeyOpen.off}
        queryKey={queryKey}
        endpointId={endpointId}
      />
    </Stat>
  );
}
const oneHour = 3600;
const oneDay = oneHour * 24;

const oldKeyExpirationOptions = {
  0: "Now (expire immediately)",
  [oneHour]: "1 hour",
  [oneHour * 4]: "4 hours",
  [oneDay]: "24 hours",
  [oneDay * 2]: "48 hours",
} as const;

const newKeyExpirationOptions = {
  [0]: "No expiration",
  [oneDay * 365]: "1 year",
  [oneDay * 90]: "3 months",
  [oneDay * 30]: "1 month",
  [oneDay * 7]: "1 week",
  [oneDay]: "1 day",
} as const;

function RotateEndpointToken({
  isOpen,
  onClose,
  queryKey,
  endpointId,
}: {
  isOpen: boolean;
  onClose: () => void;
  queryKey: string[];
  endpointId: string;
}) {
  const queryClient = useQueryClient();
  const formCtx = useForm<{
    oldExpiration: number;
    newExpiration: number;
  }>({
    defaultValues: {
      oldExpiration: oneHour,
      newExpiration: 0,
    },
  });
  const [newKey, setNewKey] = useState<AuthTokenOut>();
  const appId = useAppIdSelector()!;

  const oldExpirationDate =
    formCtx.watch("oldExpiration") > 0
      ? new Date(Date.now() + formCtx.watch("oldExpiration") * 1000)
      : undefined;

  const newExpirationDate =
    formCtx.watch("newExpiration") > 0
      ? new Date(Date.now() + formCtx.watch("newExpiration") * 1000)
      : undefined;

  const [isLoading, _, onSubmit] = useLoadingManual(
    async (data: { oldExpiration: number; newExpiration: number }) => {
      const { oldExpiration, newExpiration } = data;
      const svix = getSvix();
      const api = new AuthenticationApi(svix._configuration);
      const newKey = await api.v1AuthenticationRotatePollerToken({
        appId,
        endpointId,
        rotatePollerTokenIn: {
          oldTokenExpiry: Number(oldExpiration) || 0,
          expiry: newExpiration > 0 ? Number(newExpiration) : undefined,
        },
      });

      queryClient.invalidateQueries(queryKey);
      setNewKey(newKey);
    },
    [endpointId, appId]
  );

  const onModalClose = () => {
    setNewKey(undefined);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} size="lg">
      <ModalOverlay />
      {newKey ? (
        <ModalContent>
          <ModalHeader>API Key Rotated</ModalHeader>
          <ModalBody>
            <NewKeyAlert newKey={newKey} />
          </ModalBody>
          <ModalFooter>
            <Button type="button" colorScheme="gray" onClick={onModalClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <Form {...formCtx} onSubmit={onSubmit}>
          <ModalContent>
            <ModalHeader>
              Rotate Endpoint API Key
              <Text fontSize="sm" color="text.muted">
                Set the expiration for the old key and the new key.
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack alignItems="center" justifyContent="space-between">
                <Text w="30%" textAlign="right" fontWeight="semibold">
                  Old key
                </Text>
                <SelectField
                  w="100%"
                  control={formCtx.control}
                  name="oldExpiration"
                  defaultValue={oneHour}
                >
                  {Object.entries(oldKeyExpirationOptions).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </SelectField>
              </HStack>
              {oldExpirationDate && (
                <Text textAlign="right" color="text.muted" mt={2} fontSize="sm">
                  Expires at {formatDateTime(oldExpirationDate)}
                </Text>
              )}
              <HStack mt={8} alignItems="center" justifyContent="space-between">
                <Text w="30%" textAlign="right" fontWeight="semibold">
                  New key
                </Text>
                <SelectField
                  w="100%"
                  control={formCtx.control}
                  name="newExpiration"
                  defaultValue={0}
                >
                  {Object.entries(newKeyExpirationOptions).map(([value, label]) => (
                    <option key={value} value={Number(value)}>
                      {label}
                    </option>
                  ))}
                </SelectField>
              </HStack>
              {newExpirationDate && (
                <Text textAlign="right" color="text.muted" mt={2} fontSize="sm">
                  Expires at {formatDateTime(newExpirationDate)}
                </Text>
              )}
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Spacer />
                <Button type="button" colorScheme="gray" onClick={onClose} mr={2}>
                  Nevermind
                </Button>
                <SubmitButton type="submit" colorScheme="red" isLoading={isLoading}>
                  Rotate
                </SubmitButton>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Form>
      )}
    </Modal>
  );
}

export function NewEndpointToken({
  isOpen,
  onClose,
  queryKey,
  endpointId,
}: {
  isOpen: boolean;
  onClose: () => void;
  queryKey: string[];
  endpointId: string;
}) {
  const queryClient = useQueryClient();
  const [newKey, setNewKey] = useState<AuthTokenOut>();
  const formCtx = useForm<{
    expiration: number;
  }>({
    defaultValues: {
      expiration: 0,
    },
  });
  const appId = useAppIdSelector()!;

  const [isLoading, _, onSubmit] = useLoadingManual(
    async (data: { expiration: number }) => {
      const { expiration } = data;
      const svix = getSvix();
      const api = new AuthenticationApi(svix._configuration);
      const newKey = await api.v1AuthenticationRotatePollerToken({
        appId,
        endpointId,
        rotatePollerTokenIn: {
          expiry: expiration > 0 ? Number(expiration) : undefined,
        },
      });

      queryClient.invalidateQueries(queryKey);
      setNewKey(newKey);
    },
    [endpointId, appId]
  );

  const onModalClose = () => {
    setNewKey(undefined);
    onClose();
  };

  const expirationDate =
    formCtx.watch("expiration") > 0
      ? new Date(Date.now() + formCtx.watch("expiration") * 1000)
      : undefined;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      {newKey ? (
        <ModalContent>
          <ModalHeader>New API Key Created</ModalHeader>
          <ModalBody>
            <NewKeyAlert newKey={newKey} />
          </ModalBody>
          <ModalFooter>
            <Button type="button" colorScheme="gray" onClick={onModalClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <Form {...formCtx} onSubmit={onSubmit}>
          <ModalContent>
            <ModalHeader>New Polling Endpoint API Key</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SelectField
                control={formCtx.control}
                name="expiration"
                defaultValue={0}
                label="Expires in"
              >
                {Object.entries(newKeyExpirationOptions).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </SelectField>
              {expirationDate && (
                <Text color="text.muted" mt={2} fontSize="sm">
                  Expires at {formatDateTime(expirationDate)}
                </Text>
              )}
              <Text color="text.muted" mt={2} fontSize="sm">
                The key can be rotated at any time.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Spacer />
                <SubmitButton type="submit" colorScheme="brand" isLoading={isLoading}>
                  Create
                </SubmitButton>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Form>
      )}
    </Modal>
  );
}

// FIXME /events: move to frontend-common and reuse in dashboard
function NewKeyAlert({ newKey }: { newKey: AuthTokenOut }) {
  const [copied, copyToClipboard] = useCopyToClipboard(newKey.token);

  return (
    <Box>
      <Alert status="info" mb={4} borderRadius="md">
        <AlertIcon />
        You will not be able to reveal this key again, so please make sure to take note of
        it now.
      </Alert>
      <Box p={4} border="1px solid" borderColor="gray.200" borderRadius="md">
        <Stack spacing={2}>
          {newKey.name && (
            <Text>
              <Text fontWeight="semibold" display="inline">
                Name:
              </Text>{" "}
              {newKey.name}
            </Text>
          )}
          {newKey.scopes && (
            <Text>
              <Text fontWeight="semibold" display="inline">
                Scopes:
              </Text>{" "}
              <Code>{newKey.scopes.join(", ")}</Code>
            </Text>
          )}
          {newKey.expiresAt && (
            <Text>
              <Text fontWeight="semibold" display="inline">
                Expires at:
              </Text>{" "}
              {formatDateTime(newKey.expiresAt)}
            </Text>
          )}
          <Text>
            <Text fontWeight="semibold" display="inline">
              Key:
            </Text>{" "}
            <Tooltip
              label={copied ? "Copied" : "Click to copy"}
              hasArrow
              closeOnClick={false}
            >
              <Box display="inline-block" cursor="pointer" onClick={copyToClipboard}>
                <HStack alignItems="center" w="fit-content">
                  <Code>{newKey.token}</Code> <FileCopy />
                </HStack>
              </Box>
            </Tooltip>
          </Text>
        </Stack>
      </Box>
    </Box>
  );
}
