import { Box, Divider, Flex, Stack, HStack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForwardIos } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { EndpointIn } from "svix";
import * as yup from "yup";

import * as C from "@svix/common/constants";
import { setErrors } from "@svix/common/formUtils";
import { capitalize } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import StyledLink from "@svix/common/widgets/Link";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SinksApi } from "src/api/sinks";
import { routeResolver } from "src/App";
import { useAllEventTypes } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";
import { MAX_CHANNELS } from "../Endpoint/properties/Channels";
import ChannelsAutocomplete from "../Endpoint/properties/ChannelsAutosuggest";

const schema = yup.object().shape({
  filterTypes: yup.mixed(),
  channels: yup.array().of(yup.string()).max(MAX_CHANNELS).nullable(),
});

const DEFAULT_VALUES = {
  filterTypes: [] as string[],
  channels: [] as string[],
};

export default function PollingEndpointForm() {
  const defaultValues = DEFAULT_VALUES as EndpointIn;
  const user = useAppSelector((state) => state.auth.user)!;
  const { stringsOverrides, hideEventTypes } = useAppSelector(
    (state) => state.embedConfig
  );
  const history = useHistory();
  const formCtx = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: orgSettings } = useOrgSettings();

  const { data: availableEvents } = useAllEventTypes();

  async function onAddPollingEndpoint(form: EndpointIn) {
    if (form.filterTypes && form.filterTypes.length === 0) {
      delete form["filterTypes"];
    }
    if (form.channels && form.channels.length === 0) {
      delete form["channels"];
    }

    // Add user-friendly uid to obfuscate the fact that this is an endpoint
    const randomChars = Math.random().toString(36).substring(2, 5);
    form.uid = `poll_${randomChars}`;

    try {
      const svix = getSvix();
      const sinksApi = new SinksApi(svix);
      const res = await sinksApi.createSink(user.app.id, {
        ...form,
        type: "eventStream",
      });
      history.push(routeResolver.getRoute("endpoints.polling._id", { sinkId: res.id }));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  return (
    <Form onSubmit={onAddPollingEndpoint} {...formCtx}>
      <Box maxW="50em">
        <Stack spacing={5}>
          <Stack spacing={5}>
            <Stack spacing={2}>
              <Text fontSize="md" fontWeight="medium">
                Create Polling Endpoint
              </Text>
              <Text>
                Polling Endpoints are a way for your users to get a stream of events by
                polling instead of listening to webhooks. Read more{" "}
                <StyledLink isExternal to={C.docs.advanced.pollingEndpoints}>
                  in the docs
                </StyledLink>
                .
              </Text>
            </Stack>
            {!hideEventTypes && (
              <EventsList
                availableEvents={availableEvents?.data || []}
                control={formCtx.control}
                name="filterTypes"
                label={
                  <Flex alignItems="center" justifyContent="space-between">
                    <span>Subscribe to events</span>
                    <StyledLink
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                      color="interactive.accent"
                      to={routeResolver.getRoute("event-types")}
                    >
                      Event Catalog
                      <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
                    </StyledLink>
                  </Flex>
                }
                emptyState="Receiving all events."
              />
            )}
            {orgSettings?.enableChannels && (
              <ChannelsAutocomplete
                label={capitalize(stringsOverrides.channelsMany)}
                name="channels"
                control={formCtx.control}
                helperText={stringsOverrides.channelsHelp}
              />
            )}
          </Stack>
        </Stack>
        <GeneralFormErrors />
        <Divider mt={4} />

        <HStack mt={4} spacing={4}>
          <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("endpoints")}>
            Cancel
          </Button>

          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Create</SubmitButton>
        </HStack>
      </Box>
    </Form>
  );
}
