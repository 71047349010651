import * as React from "react";
import { KeyboardEventHandler, useState } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
  Text,
} from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import { FieldError, useController } from "react-hook-form";

import { useAppSelector } from "src/hooks/store";
import { MAX_CHANNELS } from "./Channels";

interface IAutocompleteProps extends InputProps {
  control: any;
  name: string;
  label?: React.ReactNode | string;
  helperText?: React.ReactNode | string;
  required?: boolean;
  maxChannels?: number;
}

function getErrorMessage(error: FieldError | undefined): string {
  if (!error) {
    return "";
  }
  if (error.message) {
    return error.message;
  }
  if (error.type === "required") {
    return "Required";
  }
  if (error.type === "maxLength") {
    return "Max length exceeded";
  }
  return "Invalid";
}

export default function ChannelsAutocomplete({
  control,
  helperText,
  label,
  name,
  placeholder,
  required,
  maxChannels = MAX_CHANNELS,
}: IAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const stringsOverride = useAppSelector((state) => state.embedConfig.stringsOverrides);

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: [],
  });
  const { ref, ...controlProps } = field;
  const value = field.value || [];

  const errorMessage = getErrorMessage(error);

  const ChannelsHelpMessage = () => {
    return (
      <Flex mx={2} px={2}>
        <Text variant="caption" fontSize="sm">
          {`You can add up to ${maxChannels} ${stringsOverride.channelsMany} per endpoint.`}
        </Text>
      </Flex>
    );
  };

  const isValidNewOption = (inputValue: string, value: any) => {
    const trimmed = inputValue.trim();
    return trimmed.length > 0 && value.length < maxChannels && !value.includes(trimmed);
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue || inputValue === "") {
      // Prevent form submission with enter
      if (event.key === "Enter") {
        event.preventDefault();
      }

      // Delete last item with backspace
      if (event.key === "Delete" || event.key === "Backspace") {
        controlProps.onChange(value.slice(0, -1));
      }

      return;
    }

    if (["Enter", "Tab", " "].includes(event.key)) {
      if (isValidNewOption(inputValue, value)) {
        controlProps.onChange([...value, inputValue.trim()]);
        setInputValue("");
      }
      event.preventDefault();
    }
  };

  return (
    <FormControl isRequired={required} isInvalid={invalid}>
      {label !== undefined && <FormLabel>{label}</FormLabel>}
      <CreatableSelect
        defaultValue={[]}
        components={{
          NoOptionsMessage: ChannelsHelpMessage,
          DropdownIndicator: () => null,
        }}
        onChange={(newValue) => controlProps.onChange(newValue.map((v: any) => v.value))}
        onInputChange={(newValue) => setInputValue(newValue)}
        inputValue={inputValue}
        onKeyDown={handleKeyDown}
        value={value.map((v: string) => ({ label: v, value: v }))}
        placeholder={placeholder ?? "Type to add..."}
        isMulti
        name="channels"
        options={[]}
        formatCreateLabel={(input) => `Add "${input}" as ${stringsOverride.channelsOne}`}
        isValidNewOption={isValidNewOption}
      />
      {helperText !== undefined && !error && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
