import { useState } from "react";
import {
  Box,
  IconButton,
  SkeletonText,
  Thead,
  Tr,
  Th,
  Tbody,
  Flex,
  Heading,
  Text,
  useToast,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBoolean,
} from "@chakra-ui/react";
import { ArrowDropDown, Replay } from "@material-ui/icons";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  MessageStatus,
  MessageAttemptListOptions,
  EndpointOut,
  MessageAttemptListByEndpointOptions,
} from "svix";
import {
  ListResponseEndpointMessageOut,
  ListResponseMessageAttemptOut,
  MessageAttemptApi,
  MessageAttemptOut,
  EndpointMessageOut,
  MessageOut,
} from "svix/dist/openapi";

import { useSearch } from "@svix/common/hooks/search";
import { capitalize } from "@svix/common/utils";
import {
  DateFilterChoice,
  useInitialDateFilter,
  NOW_FILTER,
  EARLIEST_DATE_FILTER,
  encodeCustomDateFilter,
  LAST_MONTH_FILTER,
} from "@svix/common/widgets/DateFilter/DateFilter";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getSvix } from "src/api";
import { useAppPagination, useAppQuery } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";
import { isEE } from "src/utils";
import EndpointTableRow from "./EndpointRow";
import MessageStatusFilter from "./MessageStatusFilter";
import FilterMenu, { FilterType } from "../../components/FilterMenu";

function useInitialStatus() {
  const status = useSearch("status");
  if (status) {
    return Number(status);
  }
  return undefined;
}

function useInitialEventTypes() {
  const savedVal = useSearch("eventTypes");
  if (!savedVal) {
    return [] as string[];
  }

  return savedVal.split(",");
}

export default function AttemptsTable(props: { endpoint: EndpointOut }) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const { stringsOverrides, hideEventTypes } = useAppSelector(
    (state) => state.embedConfig
  );
  const { endpId } = useParams<{ endpId: string }>();
  const queryKey = ["endpoints", endpId];

  const [filterStatus, setFilterStatus] = useState<MessageStatus | undefined>(
    useInitialStatus()
  );

  const defaultValues = {
    eventTypes: useInitialEventTypes(),
    before: useInitialDateFilter("before", NOW_FILTER),
    after: useInitialDateFilter("after", EARLIEST_DATE_FILTER),
    channels: useSearch("channels") ?? "",
    tags: useSearch("tags") ?? "",
  };

  const formCtx = useForm({ defaultValues });

  let filterCount = 0;

  const eventTypes = useWatch({
    control: formCtx.control,
    name: "eventTypes",
    defaultValue: defaultValues.eventTypes,
  });
  filterCount += eventTypes.length;

  const afterDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "after",
    defaultValue: defaultValues.after,
  });
  if (afterDateFilter.value !== EARLIEST_DATE_FILTER.value) {
    filterCount++;
  }

  const beforeDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "before",
    defaultValue: defaultValues.before,
  });
  if (beforeDateFilter.value !== NOW_FILTER.value) {
    filterCount++;
  }

  const channel = useWatch({
    control: formCtx.control,
    name: "channels",
    defaultValue: defaultValues.channels,
  });
  if (channel) {
    filterCount++;
  }

  const tags = useWatch({
    control: formCtx.control,
    name: "tags",
    defaultValue: defaultValues.tags,
  });
  if (tags) {
    filterCount++;
  }

  const toast = useToast();

  const tryShortenQuery = (current?: Date) => {
    if (current) {
      const newDate = new Date(current);
      newDate.setDate(current.getDate() + 7);
      if (newDate > new Date()) {
        return undefined;
      }
      return newDate;
    }
    return LAST_MONTH_FILTER.getDate();
  };

  const [showAttemptedMessages, setShowAttemptedMessages] = useBoolean(false);

  const filterKeys = [
    String(filterStatus ?? "all"),
    afterDateFilter.value,
    beforeDateFilter.value,
    channel,
    tags,
    eventTypes.join(","),
  ];

  const [messageAttempts, messageAttemptsCtx] =
    useAppPagination<ListResponseMessageAttemptOut>(
      [...queryKey, "messageAttempts", ...filterKeys],
      async (iterator) => {
        const api = getSvix();
        let after = afterDateFilter.getDate();
        let canShorten = true;
        while (canShorten) {
          try {
            const res = await api.messageAttempt.listByEndpoint(user.app.id, endpId, {
              iterator,
              limit: 20,
              status: filterStatus,
              channel: channel || undefined,
              before: beforeDateFilter.getDate(),
              after,
              eventTypes,
              tag: tags || undefined, // FIXME: missing in the lib type
              withMsg: true,
            } as MessageAttemptListByEndpointOptions & { tag?: string });
            formCtx.setValue(
              "after",
              after ? encodeCustomDateFilter(after) : EARLIEST_DATE_FILTER
            );
            return res;
          } catch (e) {
            if (e?.body?.code === "too_much_data" || e?.body?.code === "query_timeout") {
              // Shorten the date range in 7 day increments until the query works
              const shortened = tryShortenQuery(after);
              canShorten = !!shortened;
              after = shortened;
              toast({
                duration: 4000,
                status: "info",
                title: "There is too much data to fulfill the request",
                description: "Shortening the date range by 7 days.",
              });
            } else {
              throw e;
            }
          }
        }

        return {
          data: [],
          done: true,
          iterator: null,
        };
      }
    );

  const { data: countMessageAttempts } = useAppQuery(
    [...queryKey, "countMessageAttempts", ...filterKeys],
    async () => {
      // EE doesn't support v1MessageAttemptCountByEndpoint
      if (isEE) {
        return undefined;
      }

      const sv = getSvix();
      const api = new MessageAttemptApi(sv._configuration);
      const res = await api.v1MessageAttemptCountByEndpoint({
        appId: user.app.id,
        endpointId: endpId,
        status: filterStatus,
        channel: channel || undefined,
        before: beforeDateFilter.getDate(),
        after: afterDateFilter.getDate(),
        eventTypes,
        tag: tags || undefined, // FIXME: missing in the lib type
      });
      return res;
    }
  );

  const [attemptedMessages, attemptedMessagesCtx] =
    useAppPagination<ListResponseEndpointMessageOut>(
      [...queryKey, "attemptedMessages", ...filterKeys],
      async (iterator) => {
        const api = getSvix();
        const res = await api.messageAttempt.listAttemptedMessages(user.app.id, endpId, {
          iterator,
          limit: 20,
          status: filterStatus,
          channel: channel || undefined,
          before: beforeDateFilter.getDate(),
          after: afterDateFilter.getDate(),
          eventTypes,
          withContent: true,
          tag: tags || undefined, // FIXME: missing in the lib type
        } as MessageAttemptListOptions & { tag?: string });
        return res;
      }
    );

  const { data: orgSettings } = useOrgSettings();

  const filters: FilterType[] = ["date"];
  if (!hideEventTypes) {
    filters.push("eventType");
  }
  if (orgSettings?.enableChannels) {
    filters.push("channels");
  }
  if (!isEE) {
    filters.push("tags");
  }

  const [listElements, listElementsCtx] = showAttemptedMessages
    ? [attemptedMessages, attemptedMessagesCtx]
    : [messageAttempts, messageAttemptsCtx];

  return (
    <>
      <Box id="endpoint-messages-list">
        <Flex alignItems="center" mt={8} mb={4} gridGap={2}>
          <HStack>
            <Heading as="h2" size="sm">
              {showAttemptedMessages ? "Attempted Messages" : "Message Attempts"}
            </Heading>
            <Menu placement="bottom">
              <MenuButton
                size="xs"
                as={IconButton}
                variant="rounded"
                data-cy="options-button"
              >
                <ArrowDropDown />
              </MenuButton>
              <MenuList data-cy="options-menu">
                <MenuItem onClick={setShowAttemptedMessages.toggle}>
                  {showAttemptedMessages
                    ? "Ungroup attempts"
                    : "Group attempts by message"}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <Flex flexGrow={1} />
          <IconButton
            size="sm"
            aria-label="Refresh"
            variant="outline"
            isLoading={listElementsCtx.isFetching}
            onClick={listElementsCtx.refetch}
          >
            <Replay style={{ fontSize: 16 }} />
          </IconButton>
          <MessageStatusFilter value={filterStatus} onChange={setFilterStatus} />
          <FilterMenu
            filters={filters}
            control={formCtx.control}
            filterCount={filterCount}
          />
        </Flex>
        <Table
          variant="hover"
          emptyState={
            filterCount > 0 || filterStatus !== undefined ? (
              <Text variant="muted">No messages exist matching the current filter</Text>
            ) : (
              <>
                <Text variant="muted">
                  This endpoint has not received any messages yet
                </Text>
              </>
            )
          }
          response={listElements}
          count={showAttemptedMessages ? undefined : countMessageAttempts}
          requestElems={listElementsCtx}
          size="sm"
          horizScroll
        >
          <Thead>
            <Tr>
              <Th />
              {!hideEventTypes && <Th>Event Type</Th>}
              {orgSettings?.enableChannels && (
                <Th>{capitalize(stringsOverrides.channelsMany)}</Th>
              )}
              <Th>Message ID</Th>
              <Th>Timestamp</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {listElements?.data.map((attemptOrMsg) => {
              const msg: MessageOut | undefined = isMessageAttempt(attemptOrMsg)
                ? attemptOrMsg.msg
                : attemptOrMsg;

              return (
                msg && (
                  <EndpointTableRow
                    key={attemptOrMsg.id}
                    endpoint={endpoint}
                    attempt={{
                      status: attemptOrMsg.status,
                      timestamp: attemptOrMsg.timestamp,
                      msg,
                    }}
                    enableChannels={orgSettings?.enableChannels ?? false}
                  />
                )
              );
            })}
            {!listElements && (
              <Tr>
                <TableCell />
                {!hideEventTypes && (
                  <TableCell>
                    <SkeletonText noOfLines={1} />
                  </TableCell>
                )}
                {orgSettings?.enableChannels && (
                  <TableCell>
                    <SkeletonText noOfLines={1} />
                  </TableCell>
                )}
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

function isMessageAttempt(
  attempt: EndpointMessageOut | MessageAttemptOut
): attempt is MessageAttemptOut {
  return "msgId" in attempt;
}
