import { lazy, useState, Suspense } from "react";
import {
  Divider,
  Stack,
  HStack,
  Accordion,
  AccordionButton,
  AccordionIcon,
  Flex,
  AccordionItem,
  AccordionPanel,
  FormLabel,
  Skeleton,
  useToast,
  FormControl,
  Switch,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import { ListResponseEndpointOut } from "svix";
import { EndpointApi } from "svix/dist/openapi";

import { humanize } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import TestTransformation from "@svix/common/widgets/TestTransformation";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";

const CodeEditor = lazy(() => import("@svix/common/widgets/form/CodeEditor"));

export default function EndpointTransformationScreen() {
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.auth.user)!;
  const { hideEventTypes } = useAppSelector((state) => state.embedConfig);
  const { endpId } = useParams<{ endpId: string }>();
  const toast = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [code, setCode] = useState(
    hideEventTypes ? DEFAULT_CODE_NO_EVENT_TYPE : DEFAULT_CODE
  );

  const sv = getSvix();
  const config = sv._configuration;
  const endpApi = new EndpointApi(config);

  // get endpoint data
  const { data: endpoint } = useAppQuery(
    ["endpoints", endpId],
    async () => {
      const api = getSvix();
      return api.endpoint.get(user.app.id, endpId);
    },
    {
      placeholderData: () =>
        queryClient
          .getQueryData<ListResponseEndpointOut>("endpoints")
          ?.data.find((d) => d.id === endpId),
    }
  );

  // get current transformation and set it if it exists
  useAppQuery(["endpoints", endpId, "transformation"], async () => {
    const transformation = await endpApi.v1EndpointTransformationGet({
      appId: user.app.id,
      endpointId: endpId,
    });
    setEnabled(transformation.enabled || false);
    if (transformation.code) {
      setCode(transformation.code);
    }
    return transformation;
  });

  const saveTransformation = async () => {
    setLoading(true);
    await endpApi.v1EndpointTransformationPartialUpdate({
      appId: user.app.id,
      endpointId: endpId,
      endpointTransformationIn: {
        code,
        enabled,
      },
    });
    toast({
      title: "Transformation saved",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    history.push({
      pathname: routeResolver.getRoute("endpoints._id", { endpId }),
      hash: "advanced",
    });
    setLoading(false);
  };

  if (!endpoint) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <MetaTitle path={["New Transformation"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItem
            to={routeResolver.getRoute("endpoints._id", { endpId: endpoint.id })}
          >
            {humanize(endpId)}
          </BreadcrumbItem>
          <BreadcrumbItem
            to={routeResolver.getRoute("endpoints._id.transformation", {
              endpId: endpoint.id,
            })}
          >
            Transformation
          </BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Stack direction="column" spacing={5} maxW="50em">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts" mb="0">
            Enabled
          </FormLabel>
          <Switch
            isChecked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
            disabled={loading}
          />
        </FormControl>
        <FormLabel>Code</FormLabel>
        <Suspense fallback={<Skeleton h="12em" />}>
          <CodeEditor lang={Lang.Javascript} value={code} onChange={setCode} dark />
        </Suspense>
        <Flex>
          <Accordion
            allowToggle
            borderColor="transparent"
            padding={0}
            w="100%"
            defaultIndex={0}
          >
            <AccordionItem padding={0}>
              <AccordionButton
                padding={0}
                _hover={{ backgroundColor: "transparent" }}
                _focus={{ outline: "none" }}
              >
                <FormLabel size="md" as="div" flex="1" textAlign="left">
                  Test
                </FormLabel>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel padding={0}>
                <TestTransformation
                  code={code}
                  getSvix={getSvix}
                  appId={user.app.id}
                  endpointId={endpoint.id}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Stack>
      <Divider mt={4} />
      <HStack mt={4} spacing={4}>
        <Button
          colorScheme="gray"
          as={Link}
          to={{
            pathname: routeResolver.getRoute("endpoints._id", { endpId: endpoint.id }),
            hash: "advanced",
          }}
        >
          Cancel
        </Button>
        <Button onClick={saveTransformation} isLoading={loading}>
          Save
        </Button>
      </HStack>
    </>
  );
}

export const DEFAULT_CODE = `/**
 * @param webhook the webhook object
 * @param webhook.method destination method. Allowed values: "POST", "PUT"
 * @param webhook.url current destination address
 * @param webhook.eventType current webhook Event Type
 * @param webhook.payload JSON payload
 * @param webhook.cancel whether to cancel dispatch of the given webhook
 */
function handler(webhook) {
  // modify the webhook object...
  
  // and return it
  return webhook
}
`;

const DEFAULT_CODE_NO_EVENT_TYPE = `/**
 * @param webhook the webhook object
 * @param webhook.method destination method. Allowed values: "POST", "PUT"
 * @param webhook.url current destination address
 * @param webhook.payload JSON payload
 * @param webhook.cancel whether to cancel dispatch of the given webhook
 */
function handler(webhook) {
  return webhook
}
`;

export const DEFAULT_PAYLOAD = "{}";
