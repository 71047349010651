import { Box, ButtonGroup, useBoolean, Alert } from "@chakra-ui/react";
import { union } from "lodash";
import { EventTypeOut, StreamSinkIn } from "svix";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form from "@svix/common/widgets/Form";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SinksApi } from "src/api/sinks";
import { StreamSinkOut } from "src/api/streamSinks";
import { useAllEventTypes } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";

interface IEndpointEventTypes {
  sink: StreamSinkOut;
  queryKey: string[];
}

const NUM_DEFAULT_VISIBLE_ITEMS = 3;

// FIXME: Copy-pasted from Endpoint/EventTypes.tsx - refactor to reuse the component
export default function StreamEndpointEventTypes(props: IEndpointEventTypes) {
  const { sink, queryKey } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const [showAllEvents, setShowAllEvents] = useBoolean();

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<StreamSinkOut>(queryKey, sink, "eventTypes", async (form) => {
      const sv = getSvix();
      const api = new SinksApi(sv);
      if (form.eventTypes && form.eventTypes.length === 0) {
        delete form["eventTypes"];
      }
      return api.updateStreamSink(user.app.id, sink.id, form as StreamSinkIn);
    });

  const { data: allEvents } = useAllEventTypes();
  const availableEvents: EventTypeOut[] = union(
    allEvents?.data || [],
    // This might contain deleted or feature flagged event types not returned by useAllEventTypes
    (sink.eventTypes || []).map((ft) => ({
      name: ft,
      description: "",
      deprecated: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    }))
  );

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Stat
        name="Subscribed events"
        cta={
          isEditing ? (
            <ButtonGroup ml={2} size="xs">
              <Button colorScheme="gray" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : (
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <Button
                isDisabled={isReadOnly}
                type="button"
                size="xs"
                colorScheme="gray"
                ml={2}
                onClick={edit}
                key="edit"
              >
                Edit
              </Button>
            </ReadOnlyTooltip>
          )
        }
      >
        {isEditing ? (
          <>
            {formCtx.formState.errors.eventTypes && (
              <Alert status="error">
                {
                  /* Have to cast because the type from this library is wrong */
                  (formCtx.formState.errors.eventTypes as any).message
                }
              </Alert>
            )}
            <EventsList
              availableEvents={availableEvents}
              name="eventTypes"
              control={formCtx.control}
            />
          </>
        ) : sink.eventTypes ? (
          <Box>
            {sink.eventTypes
              .slice(0, showAllEvents ? undefined : NUM_DEFAULT_VISIBLE_ITEMS)
              .map((eventType) => (
                <Box key={eventType}>{eventType}</Box>
              ))}
            {!showAllEvents && sink.eventTypes.length > NUM_DEFAULT_VISIBLE_ITEMS && (
              <Button
                size="xs"
                colorScheme="blue"
                variant="ghost"
                onClick={setShowAllEvents.on}
              >
                + {sink.eventTypes.length - NUM_DEFAULT_VISIBLE_ITEMS} More
              </Button>
            )}
          </Box>
        ) : (
          "Listening to all events"
        )}
      </Stat>
    </Form>
  );
}
