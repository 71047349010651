interface SinkConfigMetadata {
  key: string;
  label: string;
  highlight?: boolean;
  placeholder?: string;
  description?: string;
  sensitive?: boolean;
  optional?: boolean;
}

// FIXME STREAM_APP_PORTAL: Add strong typing to ensure the keys are valid for each sink type
export const SinkTypesMetadata: Record<
  string,
  { name: string; values: Array<SinkConfigMetadata> }
> = {
  amazonS3: {
    name: "Amazon S3",
    values: [
      {
        key: "bucket",
        label: "Bucket",
        highlight: true,
        placeholder: "my-bucket",
      },
      {
        key: "region",
        label: "Region",
        highlight: true,
        placeholder: "us-east-1",
      },
      {
        key: "accessKeyId",
        label: "Access Key ID",
      },
      {
        key: "secretAccessKey",
        label: "Secret Access Key",
        sensitive: true,
        placeholder: "********",
      },
    ],
  },
  http: {
    name: "HTTP",
    values: [
      {
        key: "url",
        label: "URL",
        highlight: true,
        description: "The URL to send the events to",
        placeholder: "https://example.com/events",
      },
    ],
  },
  snowflake: {
    name: "Snowflake",
    values: [
      {
        key: "accountIdentifier",
        label: "Account Identifier",
        highlight: true,
        placeholder: "my-org-id-my-account-id",
        description:
          "Snowflake account identifier, which includes both the organization and account IDs separated by a hyphen",
      },
      {
        key: "userId",
        label: "User",
        highlight: true,
        placeholder: "my-user",
      },
      {
        key: "dbParams.dbName",
        label: "Database Name",
        placeholder: "my-database",
      },
      {
        key: "dbParams.schemaName",
        label: "Database Schema",
        placeholder: "my-schema",
      },
      {
        key: "dbParams.tableName",
        label: "Table Name",
        placeholder: "my-table",
      },
      {
        key: "privateKey",
        label: "Private Key",
        sensitive: true,
        placeholder: "********",
        description:
          "PEM-encoded private key used for signing token-based requests to the Snowflake API.",
      },
    ],
  },
  googleCloudStorage: {
    name: "Google Cloud Storage",
    values: [
      {
        key: "bucket",
        label: "Bucket",
        highlight: true,
        placeholder: "my-bucket",
      },
      {
        key: "credentials",
        label: "Credentials",
        sensitive: true,
        placeholder: "********",
        description: "Google Cloud Credentials JSON Object as a string.",
      },
    ],
  },
  azureBlobStorage: {
    name: "Azure Blob Storage",
    values: [
      {
        key: "container",
        label: "Container",
        highlight: true,
        placeholder: "my-container",
      },
      {
        key: "account",
        label: "Account",
        highlight: true,
        placeholder: "my-account",
      },
      {
        key: "accessKey",
        label: "Access Key",
        sensitive: true,
        placeholder: "********",
      },
    ],
  },
  otelV1HttpTrace: {
    name: "Otel V1 HTTP Trace",
    values: [
      {
        key: "url",
        label: "URL",
        highlight: true,
        placeholder: "https://example.com/events",
      },
    ],
  },
  bigQuery: {
    name: "BigQuery",
    values: [
      {
        key: "projectId",
        label: "Project ID",
        highlight: true,
        placeholder: "my-project",
      },
      {
        key: "datasetId",
        label: "Dataset ID",
        highlight: true,
        placeholder: "my-dataset",
      },
      {
        key: "tableId",
        label: "Table ID",
        highlight: true,
        placeholder: "my-table",
      },
      {
        key: "credentials",
        label: "Credentials",
        sensitive: true,
        placeholder: "********",
        description: "Google Cloud Credentials JSON Object as a string.",
      },
    ],
  },
  redshift: {
    name: "Redshift",
    values: [
      {
        key: "accessKeyId",
        label: "Access Key ID",
        highlight: true,
        placeholder: "my-access-key",
      },
      {
        key: "secretAccessKey",
        label: "Secret Access Key",
        sensitive: true,
        placeholder: "********",
      },
      {
        key: "region",
        label: "Region",
        highlight: true,
        placeholder: "us-east-1",
      },
      {
        key: "dbUser",
        label: "Database User",
        placeholder: "my-user",
      },
      {
        key: "clusterIdentifier",
        label: "Cluster Identifier",
        placeholder: "my-cluster",
      },
      {
        key: "dbParams.dbName",
        label: "Database Name",
        placeholder: "my-database",
      },
      {
        key: "dbParams.schemaName",
        label: "Database Schema",
        placeholder: "my-schema",
      },
      {
        key: "dbParams.tableName",
        label: "Table Name",
        placeholder: "my-table",
      },
    ],
  },
};
