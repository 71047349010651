import * as React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Skeleton,
  Switch,
  SwitchProps,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

export interface IToggleFieldProps extends SwitchProps {
  control: any;
  name: string;
  label: React.ReactNode | string;
  helpText?: React.ReactNode | string;
  isLoading?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ToggleField({
  children,
  control,
  name,
  helpText,
  label,
  isRequired,
  isLoading,
  onChange,
  ...switchProps
}: IToggleFieldProps) {
  const {
    field: { ref, ...controlProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required: isRequired },
    defaultValue: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    controlProps.onChange(event);
    onChange?.(event);
  };

  return (
    <>
      <FormControl id={name} isInvalid={invalid}>
        <Box display="flex" alignItems="center" mb={2}>
          <Skeleton isLoaded={!isLoading} mr={4}>
            <Switch
              {...switchProps}
              {...controlProps}
              required={isRequired}
              onChange={handleChange}
              isChecked={controlProps.value ?? false}
            />
          </Skeleton>
          <FormLabel mb={0}>{label}</FormLabel>
        </Box>
        {!error && helpText && (
          <FormHelperText id={name} color="text.muted">
            {helpText}
          </FormHelperText>
        )}
        {error && <FormHelperText id={name}>{error.message}</FormHelperText>}
      </FormControl>
    </>
  );
}
